/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";
import { toast } from "react-toastify";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import load1 from "../../../assets/images/icons/Spinner.gif";
import Moment from "moment";
import ConditionFilter from "../../../components/Filters/condition-filter";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { Badge } from "antd";
let rows: any = [];
let columns: GridColDef[] = [];

function ProductsTable(props) {
  let apiEndPoint =
    "https://adsexpert-api.getgrowth.agency/Ads/SPCampaignManager/" +
    props.tabName;
  let pageload = true;
  let exporturl =""
  const [spinner, setSpinner] = useState(false);
  const [metaData, setMetaData] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState<any[]>([]);
  let filterData: any = [];
  let Condition: any = [];

  const [globalFilterFromData, setGlobalFilterFromData] = useState<any[]>([]);
  const [searchKeyFilter, setSearchKeyFilter] = useState("");

  // aaa
  const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);
  const [dropdownDataFilter, SetDropdownDataFilter] = useState<any[]>([]);

  const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
  const [filter, setFilter] = useState<string[]>([]); 
  const [filterShow, setFilterShow] = useState(false);
  const [sliceIndex, setSliceIndex] = useState<any>();
  const [statusModal, setStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Select Status");
  const [selectedRowid, setSelectedRows] = useState<any>([]);
  const [selectedStatusRow, setSelectedStatusRow] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [sortModeldata, setSortModelData] = useState<any>([]);

  useEffect(() => {
    setMetaData(props.metaData);
  }, [props.metaData, metaData]);

  useEffect(() => {
    if (props.tabName && pageload) {
      // fetchData();
      pageload = false;
    }
  }, []);



  useEffect(() => {
    setApiLoading(true);
    if (props.condition && props.selectedProfiles && props.filterDateRange ) {
      if (filterDateRange !== props.filterDateRange) {
        setFilterDateRange(props.filterDateRange);
      
      }
      if(filter.length > 0){
        setFilterShow(true)
      }
      else{
        setFilterShow(false)
      }

      columns=[]
   
     fetchData()
    }
  }, [props.condition, props.selectedProfiles, props.filterDateRange,sortModeldata,activePage,perPage,globalFilterFromData,searchKeyFilter]);

  const fetchData = async () => {
    setApiLoading(true);

    if(props.selectedProfiles === "SP"){
      apiEndPoint= "https://adsexpert-api.getgrowth.agency/Ads/SPCampaignManager/" +  props.tabName;
     }
     else if(props.selectedProfiles === "SD"){
       apiEndPoint= "https://adsexpert-api.getgrowth.agency/Ads/SDCampaignManager/" +  props.tabName;
     }

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = apiEndPoint;
    let advancedFilters: any = [];
    let advancedFilterDateRange: any = {};

    if (globalFilterFromData.length > 0) {
      advancedFilters = globalFilterFromData;
    }
    if (props.filterDateRange.length > 0) {
      advancedFilterDateRange = {
        dateRange: "Custom",
        startDate:
          Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
        endDate:
          Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
      };
    }
    let requestOptions: any = {};
    if (sortModeldata.length > 0) {
      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          profileId: Number(props.condition),
          pageNumber: activePage,
          pageSize: perPage,
          sortOptions: [
            {
              columnName: sortModeldata[0].field,
              direction: sortModeldata[0].sort=== "asc" ?"Ascending":"Descending",
            },
          ],
          globalFilters: {
            searchText: searchKeyFilter,
            advancedFilters: advancedFilters,
            dateRanges: advancedFilterDateRange,
          },
        }),
      };
    } else {
      requestOptions = {
        method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId: Number(props.condition),
        pageNumber: activePage,
        pageSize: perPage,
        globalFilters: {
          searchText: searchKeyFilter,
          advancedFilters: advancedFilters,
          dateRanges: advancedFilterDateRange,
        },
      }),
      };
    }
    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      setApiLoading(false)
      let result = responceData.result;
      setTotalRow(result.filteredCount);
      setPerPage(result.perPage);
      setActivePage(result.currPage);
      setLastPage(result.lastPage);
      let headers = responceData.result.headers;

      setDropdownDatas(headers);
      SetDropdownDataFilter(headers);

      if (columns.length < 1) {
        for (let i = 0; headers.length > i; i++) {
          if (headers[i]["keyName"] === "status") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"status " + params.row.status}></i>
              ),
              description:headers[i]["fullName"]
            });
          } else if (headers[i]["keyName"] === "marketplace") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"flag-" + params.row.marketplace}></i>
              ),
              description:headers[i]["fullName"]
            });
          } 
          else if (headers[i]["keyName"] === "campaignName") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 250,
              
              description:headers[i]["fullName"]
            });
          } 
          else if (headers[i]["keyName"] === "advertisedAsin") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 120,
              description:headers[i]["fullName"]
            });
          } 
          else if (headers[i]["keyName"] === "adGroupName") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 250,
              
              description:headers[i]["fullName"]
            });
          } 
          else if (headers[i]["keyName"] === "acos") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <span>{params.row.acos} %</span>
              ),
              description:headers[i]["fullName"]
            });
          }
          else if (headers[i]["keyName"] === "roas") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <span>{params.row.roas} %</span>
              ),
              description:headers[i]["fullName"]
            });
          }
          else if (headers[i]["keyName"] === "clickThroughRate") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <span>{params.row.clickThroughRate} %</span>
              ),
              description:headers[i]["fullName"]
            });
          }
          else if (headers[i]["keyName"] === "cr") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <span>{params.row.cr} %</span>
              ),
              description:headers[i]["fullName"]
            });
          }
          else if (headers[i]["keyName"] === "acosClicks7d") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <span >{params.row.acosClicks7d} %</span>
              ),
              description:headers[i]["fullName"]
            });
          }
          else if (headers[i]["keyName"] === "roasClicks7d") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <span >{params.row.roasClicks7d} %</span>
              ),
              description:headers[i]["fullName"]
            });
          }
          else {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 100,
              flex:0.5,
              description:headers[i]["fullName"]
            });
          }
        }
      }
      rows = responceData.result.data;
      setApiLoading(false);
    } catch (error) {
      toast("Something went wrong")
      setApiLoading(false);
      rows=[]
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    if(filter.length > 0){
      setFilterShow(true)
    }
    else{
      setFilterShow(false)
    }
  }, [filter])

  const handleCallback = (childData) => {
    console.log(childData);
    if (childData.length > 0) {
      const newFilters = childData.map((data) => {
        const name = data.conditions[0].columnName;
        const operator = data.conditions[0].operator;
        const value = data.conditions[0].value;
        const secondValue = data.conditions[0].secondValue;
        let newFilter;
        if (name === "Status") {
          const updateValue =
            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
          newFilter = `${name} = ${updateValue}`;
        } else if (operator === "Between") {
          if (name === "KeywordBid") {
            newFilter = `Bid Between ${value} , ${secondValue}`;
          } else if (name === "ClickThroughRate") {
            newFilter = `CTR Between ${value} , ${secondValue}`;
          } else if (name === "CostPerClick") {
            newFilter = `CPC Between ${value} , ${secondValue}`;
          } else if (name === "Cost") {
            newFilter = `Spend ${value} , ${secondValue}`;
          } else if (name === "TargetingText") {
            newFilter = `Targeting ${value} , ${secondValue}`;
          } else if (name === "AcosClicks14d" || name === "AcosClicks7d" ) {
            newFilter = `ACOS ${value} , ${secondValue}`;
          } else if (name === "RoasClicks14d" ||name === "RoasClicks7d"  ) {
            newFilter = `ROAS ${value} , ${secondValue}`;
          } else if (name === "Sales14d" || name === "Sales17" ) {
            newFilter = `Sales ${value} , ${secondValue}`;
          } else if (name === "Purchases14d" || name === "Purchases7d") {
            newFilter = `Orders ${value} , ${secondValue}`;
          } else if (name === "Purchases") {
            newFilter = `Orders ${value} , ${secondValue}`;
          } else {
            newFilter = `${name} Between ${value} , ${secondValue}`;
          }
        } else {
          if (name === "KeywordBid") {
            newFilter = `Bid ${operator} ${value}`;
          } else if (name === "ClickThroughRate") {
            newFilter = `CTR ${operator} ${value}`;
          } else if (name === "CostPerClick") {
            newFilter = `CPC ${operator} ${value}`;
          } else if (name === "Cost") {
            newFilter = `Spend ${operator} ${value}`;
          } else if (name === "TargetingText") {
            newFilter = `Targeting ${operator} ${value}`;
          } else if (name === "AcosClicks14d" || name === "AcosClicks7d" ) {
            newFilter = `ACOS ${operator} ${value}`;
          } else if (name === "RoasClicks14d" ||name === "RoasClicks7d"  ) {
            newFilter = `ROAS ${operator} ${value}`;
          } else if (name === "Sales14d" || name === "Sales17" ) {
            newFilter = `Sales ${operator} ${value}`;
          } else if (name === "Purchases14d" || name === "Purchases7d") {
            newFilter = `Orders ${operator} ${value}`;
          } else if (name === "Purchases") {
            newFilter = `Orders ${operator} ${value}`;
          }
           else if (name === "AdvertisedAsin") {
            newFilter = `Product ${operator} ${value}`;
          }
           else {
            newFilter = `${name} ${operator} ${value}`;
          }
        }
        return newFilter;
      });
      console.log(newFilters);
      setFilter(newFilters);
    } else {
      setFilter([]);
    }

    setActivePage(1);
    setGlobalFilterFromData(childData);

  };

  const cancelFilter = (i) => {
    const newFilter = filter.slice(0, i);
    setFilter(newFilter);
    setSliceIndex(i);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setActivePage(1);
      setSearchKeyFilter(event.target.value);
      event.preventDefault();
    }
  };

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    setActivePage(1);
    setPerPage(Number(e.target.value));
  };

  // aaa hide data
  const hiddenColumns = columFiltervalue;
  // console.log("hide column : " + hiddenColumns);

  const handleChange = (event, value: number) => {
    setActivePage(Number(value));
  };

  let userToken = localStorage.getItem("userToken");
  let AuthToken = "Bearer " + userToken;

  const handleExportData = async () => {
    setSpinner(true);
    if(props.selectedProfiles === "SP"){
      exporturl ="https://adsexpert-api.getgrowth.agency/Ads/SPCampaignManager/Products/Export"
    }
    else if(props.selectedProfiles === "SD"){
      exporturl ="https://adsexpert-api.getgrowth.agency/Ads/SDCampaignManager/Products/Export"
    }
    try {
      let advancedFilters: any = [];
      let advancedFilterDateRange: any = {};

      if (globalFilterFromData.length > 0) {
        advancedFilters = globalFilterFromData;
      }
      if (props.filterDateRange.length > 0) {
        advancedFilterDateRange = {
          dateRange: "Custom",
          startDate:
            Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
          endDate:
            Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
        };
      }

      const response = await fetch(
        exporturl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
           profileId:Number(props.condition),
            pageNumber: activePage,
            pageSize: perPage,
            globalFilters: {
              searchText: searchKeyFilter,
              advancedFilters: advancedFilters,
              dateRanges: advancedFilterDateRange,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to export data");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Adgroup exported_data ${advancedFilterDateRange.startDate.slice(0, 10)} - ${advancedFilterDateRange.endDate.slice(0, 10)}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // Handle success
      console.log("Export successful");
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      toast("Export failed")
      console.error("Error exporting data:", error);
    }
  };

  const bulkStatus = () => {
    if(selectedRowid.length >0){ 
      setStatusModal(true)
    }
    else{
      toast("Please select minimum one checkbox")
    }

  };

  const handleStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const ChangeStatus = () => {
    const Data = {
      type: "Target",
       reportType: props.selectedProfiles === "SP" ? "SPProduct" : "SDProduct",
      operation: "Status",
      value: {
        Status: selectedStatus,
        Target: selectedStatusRow,
      },
      identifiers: null,
    };
    const formattedObject = {
      ...Data,
      value: JSON.stringify(Data.value),
    };
    console.log(formattedObject);

    if (formattedObject) {
      setSelectedStatusRow([])
      const Status = async () => {
        let userToken = localStorage.getItem("userToken");
        let AuthToken = "Bearer " + userToken;
        let url =
          "https://adsexpert-api.getgrowth.agency/BulkUpdates/BulkRequest";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify(formattedObject),
        };
        try {
          const response = await fetch(url, requestOptions);
          const responceData = await response.json();
          console.log(responceData);
          setSelectedRows([])
           if(responceData.success === true){
        
            toast(responceData.message)
            fetchData()
           }
           else{
            toast(responceData.message)
           }
           setSelectedStatus("Select Status");
          setStatusModal(false);
        } catch (error) {
          setSelectedStatus("Select Status");
          console.log(error);
          setStatusModal(false);
        }
      };
      Status();
    }
  };
  const handleSortModelChange = (model) => {
    if(model.length > 0){
    console.log(model);
    const data = dropdownDatas.filter(
      (header) => header.keyName === model[0]?.field
    );
    console.log(data);
    setSortModel(model)

    setSortModelData((prevSortModel) => ([{
      ...prevSortModel,
      field: data[0].name,
      sort: model[0].sort
    }]));

  }
  else{
    setSortModel([])
    setSortModelData([])
  }
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Row className="mt-2 mb-2">
          <Col>
            <div className="custom-dropdown-container">
            <Badge
                    count={selectedRowid.length}
                    color="#fe9900"
                  >
              <div className="dropdownContent">
                <p
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{height:"39px"}}
                >
                  Bulk Operation
                  <i
                    className="fa fa-angle-down down-arrow-right"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="fa fa-angle-up up-arrow-right"
                    aria-hidden="true"
                  ></i>
                </p>
             
                <ul
                  className="dropdown-menu shadow rounded"
                  style={{ minWidth: "136px" }}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li onClick={ bulkStatus}>Status</li>
              
                </ul>
              </div>
              </Badge>
            </div>
          </Col>
          {statusModal && (
          <Modal
            show={statusModal}
            onHide={() => setStatusModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Status
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <select
                  className="form-select"
                  onChange={handleStatus}
                  value={selectedStatus}
                >
                  <option disabled>Select Status</option>
                  <option value="Enabled">Enabled</option>
                  <option value="Paused">Paused</option>
                </select>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <div>
                  <Button
                    className="secondary"
                    onClick={() => setStatusModal(false)}
                  >
                    Close
                  </Button>
                </div>
                <div>
                  <Button
                    className="btn btn-primary add-rules-btn "
                    onClick={ChangeStatus}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <Col>
          <div className="filter-container">
            <Row>
              <Col md={3} className="padding-lr-10"></Col>
              <Col md={9}>
                <div>
                  <form>
                    <div className="search-filter-container">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search"
                        id="globalSearch"
                        name="globalSearch"
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </form>
                </div>
                <div className="filter-item filter-link-container dropdownContent">
                  <p
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    <i>
                      <img src={FilterIcon} alt="filter icon" />
                    </i>
                    <span className="me-2">Filter</span>
                    <i
                      className="fa fa-angle-down down-arrow-right me-1 "
                      aria-hidden="true"
                    ></i>
                  </p>
                  <div
                    className="dropdown-menu dropdown-menu-lg-end"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ConditionFilter
                      parentCallback={handleCallback}
                      dropdownData={dropdownDatas}
                      metaData={metaData}
                      sliceIndex={sliceIndex}
                    />
                  </div>
                </div>
                {!spinner ? (
                  <div className="filter-item export-link-container" onClick={handleExportData}>
                    <p>
                      <i>
                        <img src={ExportIcon} alt="filter icon" />
                      </i>
                      <span >Export</span>
                    </p>
                  </div>
                ) : (
                  <div className="filter-item export-link-container">
                    <img src={load1} height={40} width={40} alt="spinner" />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {!apiLoading ? (
        <div>
             {filterShow && 
             <div className="filter-box d-flex  align-items-center">
              <FilterListOutlinedIcon  fontSize="medium"/>
            {filter.map((item, index) => (
              <div
                className="filter-content ms-3 d-flex align-items-center justify-content-between"
                key={index}
              >
                {item}
                <CancelOutlinedIcon
                 className="clear-filter ms-2"
                  fontSize="small"
                  onClick={() => cancelFilter(index)}
                />
              </div>
            ))}
          </div>
          }
        <div style={{ height: "calc(75vh - 120px)", width: "100%" }}>
          <DataGrid
            rows={rows}
            // columns={columns}
            // aaa
            columns={columns.filter(
              (col) => !hiddenColumns.includes(col.field)
            )}
            disableRowSelectionOnClick
            checkboxSelection={props.checkBox}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(id) => {
              const selectedIDs = new Set(id);
              if(props.selectedProfiles === "SD"){
                const selectedRows = rows
                .filter((row: any) => id.includes(row.id))
                .map((row: any) => ({
                  TargetId: row.adId,
                  Type: row.matchType,
                  ProfileId: props.condition,
                }));
              setSelectedStatusRow(selectedRows);
              }
              else{
                const selectedRows = rows
                .filter((row: any) => id.includes(row.id))
                .map((row: any) => ({
                  TargetId: row.adId,
                  Type: row.matchType,
                  ProfileId: props.condition,
                }));
              setSelectedStatusRow(selectedRows);
              }  
              setSelectedRows(id);
            }}

            hideFooter={true}
            rowHeight={40}
          />
          <div className="custom-table-footer">
            <Row>
              <Col md={5}>
                <form className="table-footer-left">
                  <span>Show </span>
                  <label>
                    <select
                      className="form-select"
                      defaultValue={perPage}
                      onChange={(event) => applyDataLength(event)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </label>
                  <span> of {totalRow} total entries</span>
                </form>
              </Col>
              <Col md={7}>
                <div className="table-footer-right">
                  <Stack spacing={2}>
                    <Pagination
                      count={lastPage}
                      page={activePage}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        </div>
      ) : (
        <div className="loading-container">
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress className="loading" style={{ margin: "auto" }} />
          </div>
        </div>
      )}
    </div>
  );
}
export default ProductsTable;
