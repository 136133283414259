import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./index.css";
import ActionDot from "../../assets/images/icons/more-action-icon.svg";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Popconfirm } from "antd";

let columns: GridColDef[] = [];

function TemplateListTable(props) {
  let apiEndPoint = "https://adsexpert-api.getgrowth.agency/Rule/Templates/All";
  const [apiLoading, setApiLoading] = useState(false);
  const [rows, setIsRows] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  const [tagInput, setTagInputValue] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [sortModeldata, setSortModelData] = useState<any>([]);
  const [clickedId, setClickedId] = useState("");
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#001912",
      color: "rgba(255, 255, 255, 0.87)",
      maxWidth: 350,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #001912",
    },
  }));

  useEffect(() => {
    const sort = JSON.parse(localStorage.getItem("Sort") || "[]");
    const sortData = JSON.parse(localStorage.getItem("SortData") || "[]");
   console.log(sort);
   
   if(sort.length >0){
    setSortModelData(sort)
    setSortModel(sortData)
   }
  }, [])

  useEffect(() => {
    props.handleSelectedRules(rowSelectionModel);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (
      props.pageReload ||
      props.currPage ||
      props.perPage ||
      props.filterData ||
      props.searchKey ||
      props.fetchRulesByStatus ||
      props.selectedAccount
    ) {
      if (props.selectedAccount) {
        setSelectedAccount(props.selectedAccount);
        fetchData();
      }
    }
  }, [
    props.pageReload,
    props.currPage,
    props.perPage,
    props.filterData,
    props.searchKey,
    props.fetchRulesByStatus,
    props.selectedAccount,
    sortModeldata,
  ]);

  const tagInputValue = (e) => {
    setTagInputValue(e.target.value);
  };


  const cancel = () => {};

  const deleteRule = async (id, profileId) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch(
      "https://adsexpert-api.getgrowth.agency/Rule/Templates",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          id: [id],
        }),
      }
    );
    const responceData = await response.json();

    console.log("deleteRule" + JSON.stringify(responceData));

    if (responceData.success === true) {
      const Data = async () => {
        if (props.selectedAccount !== "") {
          console.log(props.selectedAccount);
          setApiLoading(true);
          let userToken = localStorage.getItem("userToken");
          let AuthToken = "Bearer " + userToken;
          let url = apiEndPoint;
          let advancedFilters: any = [];

          if (props.filterData && props.filterData.length > 0) {
            advancedFilters = props.filterData;
          }

       
      let requestOptions: any = {};
      if (sortModeldata.length > 0) {
        requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            pageNumber: props.currPage,
            pageSize: props.perPage,
            sortOptions: [
              {
                columnName: sortModeldata[0].field,
                direction: sortModeldata[0].sort=== "asc" ?"Ascending":"Descending",
              },
            ],
            globalFilters: {
              searchText: props.searchKey,
              advancedFilters: [
                String(props.selectedAccount),
                props.fetchRulesByStatus === "All Status"
                  ? ""
                  : props.fetchRulesByStatus,
              ],
            },
          }),
        };
      } else {
        requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            pageNumber: props.currPage,
            pageSize: props.perPage,

            globalFilters: {
              searchText: props.searchKey,
              advancedFilters: [
                String(props.selectedAccount),
                props.fetchRulesByStatus === "All Status"
                  ? ""
                  : props.fetchRulesByStatus,
              ],
            },
          }),
        };
      }

          try {
            const response = await fetch(url, requestOptions);
            const responceData = await response.json();

            let pageConfigDetails = {
              total: responceData.result.total,
              currPage: responceData.result.currPage,
              lastPage: responceData.result.lastPage,
              nextPage: responceData.result.nextPage,
              perPage: responceData.result.perPage,
              prevPage: responceData.result.prevPage,
              dropdownDatas: responceData.result.headers,
            };

            props.parentCallback(pageConfigDetails);

            if (columns.length < 1) {
              let headers = responceData.result.headers;
              console.log(headers);

              for (let i = 0; headers.length > i; i++) {
                if (headers[i]["keyName"] === "status") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 80,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              onChange={() =>
                                statusHandler(params, params.row.profileId)
                              }
                              checked={
                                params.row.status === "Enabled" ? true : false
                              }
                            />
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "ruleName") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 170,
                    flex: 0.7,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            <div
                              className="col-title"
                              style={{ overflow: "hidden", whiteSpace: "normal" }}
                            >
                              {params.row.ruleName}
                            </div>
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "automationCriteria") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    flex: 1,
                    minWidth: 150,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.automationCriteria.map(
                              (automationCriteria, i) => (
                                <div
                                  key={i}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {automationCriteria}
                                </div>
                              )
                            )}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "matchTypes") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 150,
                 
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.matchTypes.map((value, i) => (
                              <div key={i}>{value}</div>
                            ))}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "frequency") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 200,
                
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            <div className="">
                              Occurence : {params.row.frequency.occurence}{" "}
                            </div>
                            <div className="">
                              Time : {params.row.frequency.time}{" "}
                            </div>
                            <div className="">
                              DateRange : {params.row.frequency.dateRange}{" "}
                            </div>
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "targetCriteria") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    flex: 1,
                    minWidth: 130,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.targetCriteria.map((value, i) => (
                              <div
                                key={i}
                                style={{ overflow: "hidden", whiteSpace: "normal" }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "actionCriteria") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    flex: 1,
                    minWidth: 130,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.actionCriteria.map((value, i) => (
                              <div
                                key={i}
                                style={{ overflow: "hidden", whiteSpace: "normal" }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "dateCreated") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    minWidth: 120,
                 
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.dateCreated}
                          </div>
                        </>
                      );
                    },
                  });
                } else {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    minWidth: 100,
                    flex: 0.5,
                  });
                }
              }
              columns.push({
                field: "rowAction",
                headerName: "",
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 50,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-action-container text-right">
                        <div
                          id={"dropdownMenuButton" + params.row.id}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ActionDot} alt="actions" />
                        </div>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby={"dropdownMenuButton" + params.row.id}
                        >
                 
                          <li>
                            <Link
                              to={
                                "/ads/add-automation-newrules/" + params.row.id
                              }
                            >
                              <p className="dropdown-item">Edit</p>
                            </Link>
                          </li>
                          <li>
                            <p
                              className="dropdown-item"
                              onClick={(e) =>
                                Duplicaate(params.row.id, params.row.profileId)
                              }
                            >
                              Duplicate
                            </p>
                          </li>
                          <Popconfirm
                            title="Delete"
                            description="Are you sure you want to delete rules?"
                            onConfirm={(e) =>
                              deleteRule(params.row.id, params.row.profileId)
                            }
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <li>
                              <p className="dropdown-item text-red">Delete</p>
                            </li>
                          </Popconfirm>
                        </ul>
                      </div>
                    </>
                  );
                },
              });
            }
            console.log("Columns header: ", columns);
            setIsRows(responceData.result.data);
            setApiLoading(false);
            console.log("Row data: ", rows);
          } catch (error) {
            setApiLoading(false);
            console.error("Error fetching data:", error);
          }
        }
      };
      Data();
      toast("Successfully Deleted");
    } else {
      toast("Unable to Deleted");
    }
  };
  
  const Duplicaate = async (id, profileId) => {
    console.log(profileId);

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch(
      "https://adsexpert-api.getgrowth.agency/Rule/Template/Duplicate/" + id,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({}),
      }
    );
    const responceData = await response.json();

    console.log("Duplicaate" + JSON.stringify(responceData));
    if (responceData.success === true) {
      const Data = async () => {
        if (props.selectedAccount !== "") {
          console.log(props.selectedAccount);
          setApiLoading(true);
          let userToken = localStorage.getItem("userToken");
          let AuthToken = "Bearer " + userToken;
          let url = apiEndPoint;
          let advancedFilters: any = [];

          if (props.filterData && props.filterData.length > 0) {
            advancedFilters = props.filterData;
          }

          let requestOptions: any = {};
          if (sortModeldata.length > 0) {
            requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthToken,
              },
              body: JSON.stringify({
                pageNumber: props.currPage,
                pageSize: props.perPage,
                sortOptions: [
                  {
                    columnName: sortModeldata[0].field,
                    direction: sortModeldata[0].sort=== "asc" ?"Ascending":"Descending",
                  },
                ],
                globalFilters: {
                  searchText: props.searchKey,
                  advancedFilters: [
                    String(props.selectedAccount),
                    props.fetchRulesByStatus === "All Status"
                      ? ""
                      : props.fetchRulesByStatus,
                  ],
                },
              }),
            };
          } else {
            requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthToken,
              },
              body: JSON.stringify({
                pageNumber: props.currPage,
                pageSize: props.perPage,
    
                globalFilters: {
                  searchText: props.searchKey,
                  advancedFilters: [
                    String(props.selectedAccount),
                    props.fetchRulesByStatus === "All Status"
                      ? ""
                      : props.fetchRulesByStatus,
                  ],
                },
              }),
            };
          }

          try {
            const response = await fetch(url, requestOptions);
            const responceData = await response.json();

            let pageConfigDetails = {
              total: responceData.result.total,
              currPage: responceData.result.currPage,
              lastPage: responceData.result.lastPage,
              nextPage: responceData.result.nextPage,
              perPage: responceData.result.perPage,
              prevPage: responceData.result.prevPage,
              dropdownDatas: responceData.result.headers,
            };

            props.parentCallback(pageConfigDetails);

            if (columns.length < 1) {
              let headers = responceData.result.headers;
              console.log(headers);

              for (let i = 0; headers.length > i; i++) {
                if (headers[i]["keyName"] === "status") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 80,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              onChange={() =>
                                statusHandler(params, params.row.profileId)
                              }
                              checked={
                                params.row.status === "Enabled" ? true : false
                              }
                            />
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "ruleName") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 170,
                    flex: 0.7,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            <div
                              className="col-title"
                              style={{ overflow: "hidden", whiteSpace: "normal" }}
                            >
                              {params.row.ruleName}
                            </div>
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "automationCriteria") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    flex: 1,
                    minWidth: 150,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.automationCriteria.map(
                              (automationCriteria, i) => (
                                <div
                                  key={i}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {automationCriteria}
                                </div>
                              )
                            )}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "matchTypes") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 150,
                 
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.matchTypes.map((value, i) => (
                              <div key={i}>{value}</div>
                            ))}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "frequency") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    width: 200,
                
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            <div className="">
                              Occurence : {params.row.frequency.occurence}{" "}
                            </div>
                            <div className="">
                              Time : {params.row.frequency.time}{" "}
                            </div>
                            <div className="">
                              DateRange : {params.row.frequency.dateRange}{" "}
                            </div>
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "targetCriteria") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    flex: 1,
                    minWidth: 130,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.targetCriteria.map((value, i) => (
                              <div
                                key={i}
                                style={{ overflow: "hidden", whiteSpace: "normal" }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "actionCriteria") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    flex: 1,
                    minWidth: 130,
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.actionCriteria.map((value, i) => (
                              <div
                                key={i}
                                style={{ overflow: "hidden", whiteSpace: "normal" }}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        </>
                      );
                    },
                  });
                } else if (headers[i]["keyName"] === "dateCreated") {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    minWidth: 120,
                 
                    renderCell: (params) => {
                      return (
                        <>
                          <div className="col-rulename">
                            {params.row.dateCreated}
                          </div>
                        </>
                      );
                    },
                  });
                } else {
                  columns.push({
                    field: headers[i]["keyName"],
                    headerName: headers[i]["displayName"],
                    minWidth: 100,
                    flex: 0.5,
                  });
                }
              }
              columns.push({
                field: "rowAction",
                headerName: "",
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 50,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-action-container text-right">
                        <div
                          id={"dropdownMenuButton" + params.row.id}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ActionDot} alt="actions" />
                        </div>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby={"dropdownMenuButton" + params.row.id}
                        >
                      
                          <li>
                            <Link
                              to={
                                "/ads/add-automation-newrules/" + params.row.id
                              }
                            >
                              <p className="dropdown-item">Edit</p>
                            </Link>
                          </li>
                          <li>
                            <p
                              className="dropdown-item"
                              onClick={(e) =>
                                Duplicaate(params.row.id, params.row.profileId)
                              }
                            >
                              Duplicate
                            </p>
                          </li>
                          <Popconfirm
                            title="Delete"
                            description="Are you sure you want to delete rules?"
                            onConfirm={(e) =>
                              deleteRule(params.row.id, params.row.profileId)
                            }
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <li>
                              <p className="dropdown-item text-red">Delete</p>
                            </li>
                          </Popconfirm>
                        </ul>
                      </div>
                    </>
                  );
                },
              });
            }
            console.log("Columns header: ", columns);
            setIsRows(responceData.result.data);
            setApiLoading(false);
            console.log("Row data: ", rows);
          } catch (error) {
            setApiLoading(false);
            console.error("Error fetching data:", error);
          }
        }
      };
      Data();
      toast("Successfully Duplicated");
    } else {
      toast("Unable to Duplicate");
    }
  };

  const statusHandler = async (params, profileId) => {
    // Update the status in the local state
    setIsRows((prevRows) =>
      prevRows.map((row) =>
        row.id === params.row.id
          ? { ...row, status: row.status === "Paused" ? "Enabled" : "Paused" }
          : row
      )
    );

    try {
      // Retrieve token from local storage and set up the authorization header
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;

      // Perform the PUT request
      const response = await fetch(
        "https://adsexpert-api.getgrowth.agency/Rule/Status",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            ruleId: [params.row.id],
            status: params.row.status === "Paused" ? "Enabled" : "Paused",
          }),
        }
      );

      // Parse the response data
      const responseData = await response.json();
      console.log("Duplicate" + JSON.stringify(responseData));

      // Check the response and show appropriate toast messag

      if (responseData.success === true) {
        const Data = async () => {
          if (props.selectedAccount !== "") {
            console.log(props.selectedAccount);
            setApiLoading(true);
            let userToken = localStorage.getItem("userToken");
            let AuthToken = "Bearer " + userToken;
            let url = apiEndPoint;
            let advancedFilters: any = [];

            if (props.filterData && props.filterData.length > 0) {
              advancedFilters = props.filterData;
            }

            let requestOptions: any = {};
            if (sortModeldata.length > 0) {
              requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: AuthToken,
                },
                body: JSON.stringify({
                  pageNumber: props.currPage,
                  pageSize: props.perPage,
                  sortOptions: [
                    {
                      columnName: sortModeldata[0].field,
                      direction: sortModeldata[0].sort=== "asc" ?"Ascending":"Descending",
                    },
                  ],
                  globalFilters: {
                    searchText: props.searchKey,
                    advancedFilters: [
                      String(props.selectedAccount),
                      props.fetchRulesByStatus === "All Status"
                        ? ""
                        : props.fetchRulesByStatus,
                    ],
                  },
                }),
              };
            } else {
              requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: AuthToken,
                },
                body: JSON.stringify({
                  pageNumber: props.currPage,
                  pageSize: props.perPage,
      
                  globalFilters: {
                    searchText: props.searchKey,
                    advancedFilters: [
                      String(props.selectedAccount),
                      props.fetchRulesByStatus === "All Status"
                        ? ""
                        : props.fetchRulesByStatus,
                    ],
                  },
                }),
              };
            }

            try {
              const response = await fetch(url, requestOptions);
              const responceData = await response.json();

              let pageConfigDetails = {
                total: responceData.result.total,
                currPage: responceData.result.currPage,
                lastPage: responceData.result.lastPage,
                nextPage: responceData.result.nextPage,
                perPage: responceData.result.perPage,
                prevPage: responceData.result.prevPage,
                dropdownDatas: responceData.result.headers,
              };

              props.parentCallback(pageConfigDetails);

              if (columns.length < 1) {
                let headers = responceData.result.headers;
                console.log(headers);

                for (let i = 0; headers.length > i; i++) {
                  if (headers[i]["keyName"] === "status") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      width: 80,
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                onChange={() =>
                                  statusHandler(params, params.row.profileId)
                                }
                                checked={
                                  params.row.status === "Enabled" ? true : false
                                }
                              />
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "ruleName") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      width: 170,
                      flex: 0.7,
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              <div
                                className="col-title"
                                style={{ overflow: "hidden", whiteSpace: "normal" }}
                              >
                                {params.row.ruleName}
                              </div>
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "automationCriteria") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      flex: 1,
                      minWidth: 150,
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              {params.row.automationCriteria.map(
                                (automationCriteria, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {automationCriteria}
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "matchTypes") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      width: 150,
                   
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              {params.row.matchTypes.map((value, i) => (
                                <div key={i}>{value}</div>
                              ))}
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "frequency") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      width: 200,
                  
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              <div className="">
                                Occurence : {params.row.frequency.occurence}{" "}
                              </div>
                              <div className="">
                                Time : {params.row.frequency.time}{" "}
                              </div>
                              <div className="">
                                DateRange : {params.row.frequency.dateRange}{" "}
                              </div>
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "targetCriteria") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      flex: 1,
                      minWidth: 130,
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              {params.row.targetCriteria.map((value, i) => (
                                <div
                                  key={i}
                                  style={{ overflow: "hidden", whiteSpace: "normal" }}
                                >
                                  {value}
                                </div>
                              ))}
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "actionCriteria") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      flex: 1,
                      minWidth: 130,
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              {params.row.actionCriteria.map((value, i) => (
                                <div
                                  key={i}
                                  style={{ overflow: "hidden", whiteSpace: "normal" }}
                                >
                                  {value}
                                </div>
                              ))}
                            </div>
                          </>
                        );
                      },
                    });
                  } else if (headers[i]["keyName"] === "dateCreated") {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      minWidth: 120,
                   
                      renderCell: (params) => {
                        return (
                          <>
                            <div className="col-rulename">
                              {params.row.dateCreated}
                            </div>
                          </>
                        );
                      },
                    });
                  } else {
                    columns.push({
                      field: headers[i]["keyName"],
                      headerName: headers[i]["displayName"],
                      minWidth: 100,
                      flex: 0.5,
                    });
                  }
                }
                columns.push({
                  field: "rowAction",
                  headerName: "",
                  sortable: false,
                  filterable: false,
                  disableColumnMenu: true,
                  width: 50,
                  renderCell: (params) => {
                    return (
                      <>
                        <div className="col-action-container text-right">
                          <div
                            id={"dropdownMenuButton" + params.row.id}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={ActionDot} alt="actions" />
                          </div>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby={
                              "dropdownMenuButton" + params.row.id
                            }
                          >
                      
                            <li>
                              <Link
                                to={
                                  "/ads/add-automation-newrules/" +
                                  params.row.id
                                }
                              >
                                <p className="dropdown-item">Edit</p>
                              </Link>
                            </li>
                            <li>
                              <p
                                className="dropdown-item"
                                onClick={(e) =>
                                  Duplicaate(
                                    params.row.id,
                                    params.row.profileId
                                  )
                                }
                              >
                                Duplicate
                              </p>
                            </li>
                            <Popconfirm
                              title="Delete"
                              description="Are you sure you want to delete rules?"
                              onConfirm={(e) =>
                                deleteRule(params.row.id, params.row.profileId)
                              }
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <li>
                                <p className="dropdown-item text-red">Delete</p>
                              </li>
                            </Popconfirm>
                          </ul>
                        </div>
                      </>
                    );
                  },
                });
              }
              console.log("Columns header: ", columns);
              setIsRows(responceData.result.data);
              setApiLoading(false);
              console.log("Row data: ", rows);
            } catch (error) {
              setApiLoading(false);
              console.error("Error fetching data:", error);
            }
          }
        };
        Data();
        const toastMessage =
          params.row.status === "Paused"
            ? "Successfully Enabled"
            : "Successfully Paused";
        toast(toastMessage);
      } else {
        toast("Unable to Perform Action");
      }
    } catch (error) {
      console.error(error);
      toast("An error occurred. Please try again.");
    }
  };

  const fetchData = async () => {
    if (props.selectedAccount !== "") {
      console.log(props.selectedAccount);
      setApiLoading(true);
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      let url = apiEndPoint;
      let advancedFilters: any = [];

      if (props.filterData && props.filterData.length > 0) {
        advancedFilters = props.filterData;
      }

      let requestOptions: any = {};
      if (sortModeldata.length > 0) {
        requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            pageNumber: props.currPage,
            pageSize: props.perPage,
            sortOptions: [
              {
                columnName: sortModeldata[0].field,
                direction: sortModeldata[0].sort=== "asc" ?"Ascending":"Descending",
              },
            ],
            globalFilters: {
              searchText: props.searchKey,
              advancedFilters: [
                String(props.selectedAccount),
                props.fetchRulesByStatus === "All Status"
                  ? ""
                  : props.fetchRulesByStatus,
              ],
            },
          }),
        };
      } else {
        requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            pageNumber: props.currPage,
            pageSize: props.perPage,

            globalFilters: {
              searchText: props.searchKey,
              advancedFilters: [
                String(props.selectedAccount),
                props.fetchRulesByStatus === "All Status"
                  ? ""
                  : props.fetchRulesByStatus,
              ],
            },
          }),
        };
      }

      try {
        const response = await fetch(url, requestOptions);
        const responceData = await response.json();
        let pageConfigDetails = {
          total: responceData.result.total,
          filteredCount: responceData.result.filteredCount,
          currPage: responceData.result.currPage,
          lastPage: responceData.result.lastPage,
          nextPage: responceData.result.nextPage,
          perPage: responceData.result.perPage,
          prevPage: responceData.result.prevPage,
          dropdownDatas: responceData.result.headers,
        };

        props.parentCallback(pageConfigDetails);
        setHeader(responceData.result.headers)

        if (columns.length < 1) {
          let headers = responceData.result.headers;
          console.log(headers);

          for (let i = 0; headers.length > i; i++) {
            if (headers[i]["keyName"] === "status") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                width: 80,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          onChange={() =>
                            statusHandler(params, params.row.profileId)
                          }
                          checked={
                            params.row.status === "Enabled" ? true : false
                          }
                        />
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "ruleName") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                width: 170,
                flex: 0.7,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        <div
                          className="col-title"
                          style={{ overflow: "hidden", whiteSpace: "normal" }}
                        >
                          {params.row.ruleName}
                        </div>
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "automationCriteria") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                flex: 1,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        {params.row.automationCriteria.map(
                          (automationCriteria, i) => (
                            <div
                              key={i}
                              style={{
                                overflow: "hidden",
                                whiteSpace: "normal",
                              }}
                            >
                              {automationCriteria}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "matchTypes") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                width: 150,
             
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        {params.row.matchTypes.map((value, i) => (
                          <div key={i}>{value}</div>
                        ))}
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "frequency") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                width: 200,
            
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        <div className="">
                          Occurence : {params.row.frequency.occurence}{" "}
                        </div>
                        <div className="">
                          Time : {params.row.frequency.time}{" "}
                        </div>
                        <div className="">
                          DateRange : {params.row.frequency.dateRange}{" "}
                        </div>
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "targetCriteria") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                flex: 1,
                minWidth: 130,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        {params.row.targetCriteria.map((value, i) => (
                          <div
                            key={i}
                            style={{ overflow: "hidden", whiteSpace: "normal" }}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "actionCriteria") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                flex: 1,
                minWidth: 130,
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        {params.row.actionCriteria.map((value, i) => (
                          <div
                            key={i}
                            style={{ overflow: "hidden", whiteSpace: "normal" }}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    </>
                  );
                },
              });
            } else if (headers[i]["keyName"] === "dateCreated") {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                minWidth: 120,
             
                renderCell: (params) => {
                  return (
                    <>
                      <div className="col-rulename">
                        {params.row.dateCreated}
                      </div>
                    </>
                  );
                },
              });
            } else {
              columns.push({
                field: headers[i]["keyName"],
                headerName: headers[i]["displayName"],
                minWidth: 100,
                flex: 0.5,
              });
            }
          }
          columns.push({
            field: "rowAction",
            headerName: "",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 50,
            renderCell: (params) => {
              return (
                <>
                  <div className="col-action-container text-right">
                    <div
                      id={"dropdownMenuButton" + params.row.id}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ActionDot} alt="actions" />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby={"dropdownMenuButton" + params.row.id}
                    >
                   
                      <li>
                        <Link
                          to={"/ads/add-automation-newrules/" + params.row.id +"/templateUpdate"}
                        >
                          <p className="dropdown-item">Edit</p>
                        </Link>
                      </li>
                      <li>
                        <p
                          className="dropdown-item"
                          onClick={(e) =>
                            Duplicaate(params.row.id, params.row.profileId)
                          }
                        >
                          Duplicate
                        </p>
                      </li>
                      <Popconfirm
                        title="Delete"
                        description="Are you sure you want to delete rules?"
                        onConfirm={(e) =>
                          deleteRule(params.row.id, params.row.profileId)
                        }
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <li>
                          <p className="dropdown-item text-red">Delete</p>
                        </li>
                      </Popconfirm>
                    </ul>
                  </div>
                </>
              );
            },
          });
        }
        setIsRows(responceData.result.data);
        console.log("Columns header: ", columns);
        setApiLoading(false);
        console.log("Row data: ", responceData.result.data);
      } catch (error) {
        setApiLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSortModelChange = (model) => {
    if(model.length > 0){
    console.log(model);
    const data = header.filter(
      (header) => header.keyName === model[0]?.field
    );
    console.log(data);
    setSortModel(model)

    setSortModelData((prevSortModel) => ([{
      ...prevSortModel,
      field: data[0].name,
      sort: model[0].sort
    }]));

    const sort =[{
      field: data[0].name,
      sort: model[0].sort
    }]

    localStorage.setItem("Sort", JSON.stringify(sort));
    localStorage.setItem("SortData", JSON.stringify(model));

  }
  else{
    setSortModelData([])
    setSortModel([])
  }
  };

  return (
    <div className="mt-4">
      <div style={{ height: "calc(78vh - 120px)", width: "100%" }}>
        {!apiLoading ? (
          <>
            <div
              className="modal fade"
              id="addTagModel"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel3"
            >
              <div className="modal-dialog ">
                <div className="modal-content addTagModel">
                  <Row className="addTagInputContainer">
                    <Col md={12}>
                      <input
                        type="text"
                        className="form-control"
                        onChange={tagInputValue}
                      />
                    </Col>
                    <div className="addTagbtnContainer">
                      <button
                        type="button"
                        className="addTagBtn btn btn-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        // onClick={patchTagData}
                      >
                        Add
                      </button>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              onRowSelectionModelChange={(newSelection) => {
                setRowSelectionModel(newSelection);
              }}
              disableRowSelectionOnClick
              sortModel={sortModel}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
            />
          </>
        ) : (
          <div className="loading-container">
            <div
              style={{
                position: "absolute",
                top: "30%",
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress
                className="loading"
                style={{ margin: "auto" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default TemplateListTable;
